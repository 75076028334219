import React from "react";


const Contacts = () => {
    return (
        <div className={"info-page"}>
            <p>
                For any questions, please contact info@durak.pro
            </p>
            <hr/>
            <p>
                По всем вопросам можете обращаться на почту info@durak.pro
            </p>
        </div>
    );
};

export default Contacts;
