import React, {useContext, useState} from "react";

import {UserContext} from "../context/UserContext";
import ErrorMessage from "./ErrorMessage";
import {Link, useNavigate} from "react-router-dom";
import Modal from "./Modal";
import {ModalContext} from "../context/ModalContext";

const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const setToken = useContext(UserContext).setToken;
    const setUserId = useContext(UserContext).setUserId;
    const modalActive = useContext(ModalContext).modalActive;
    const setModalActive = useContext(ModalContext).setModalActive;

    const submitRegistration = async () => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email: email, password: password, name: name, surname: surname}),
        };

        const response = await fetch("/api/auth/user", requestOptions);
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            setToken(data.access_token);
            const userRequestOptions = {
                method: "GET", headers: {
                    "Content-Type": "application/json", Authorization: "Bearer " + data.access_token,
                },
            };
            const userResponse = await fetch("/api/auth/user/me", userRequestOptions);
            const userData = await userResponse.json();
            setUserId(userData.user_id);
            setModalActive(_ => true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === confirmationPassword && password.length > 5) {
            submitRegistration();
        } else {
            setErrorMessage("Ensure that the passwords match and greater than 5 characters");
        }
    };

    return (<>
        <form className={"form"} onSubmit={handleSubmit}>
            <div className={"form-content"}>
                <div>
                    <div className={"input-atom"}>
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className={"input-atom"}>
                        <label>Surname</label>
                        <input
                            type="text"
                            placeholder="Enter surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />
                    </div>
                    <div className={"input-atom"}>
                        <label>Email Address</label>
                        <input
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={"input-atom"}>
                        <div>
                            <label>Password</label>
                            <div className="tooltip">
                                <div id={"password-hint"}>
                                    ?
                                </div>
                                <div className="tooltiptext">Введите данные в указанном формате.
                                Пароль должен содержать минимум одну цифру от 1 до 9, одну строчную букву,
                                одну заглавную букву, один специальный символ,
                                без пробелов, длина его должна составлять 8–16 символов.
                                    <hr/>
                                Enter data in the specified format.
                                Password must contain one digit from 1 to 9, one lowercase letter,
                                one uppercase letter, one special character,
                                no space, and it must be 8-16 characters long</div>
                            </div>
                        </div>
                        <input id={'ccc'}
                               type="password"
                               placeholder="Enter password"
                               value={password}
                               onChange={(e) => setPassword(e.target.value)}
                               required
                               pattern='^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$'
                        />
                    </div>
                    <div className={"input-atom"}>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={confirmationPassword}
                            onChange={(e) => setConfirmationPassword(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <ErrorMessage message={errorMessage}/>
                <button className={"input-form-button"} type="submit">
                    Register
                </button>
            </div>
            <Modal active={modalActive} setActive={setModalActive}>
                <div className={modalActive ? "modal-content active" : "modal-content"}>
                    <p>
                        Чтобы завершить процесс регистрации подтвердите вашу электронную почту в течении 7 часов,
                        иначе ваш аккаунт будет удален. <br/>
                        Письмо с ссылкой для подтверждения уже отправлено.
                        Если письмо не пришло проверьте папку спам.
                        <hr/>
                        To complete the registration process, confirm your email within 7 hours,
                        otherwise your account will be deleted. <br/>
                        An email with a confirmation link has already been sent.
                        If the email has not arrived, check your spam folder.
                    </p>
                    <button type="button" onClick={() => {
                        setModalActive(_ => false);
                        navigate("/xo_game_list")
                    }}>
                        Ok
                    </button>
                </div>
            </Modal>
        </form>

    </>);
};

export default Register;
